<template>
    <div class="section3">
        <h2>Yan</h2>
        <div class="image-container">
            <img v-if="currentImgSrc" class="img-size" :src="currentImgSrc" alt="Current Viseme" />
        </div>
        <div class="button-container">
            <button v-for="(phone, index) in selectedPhones" :key="index" class="alphabet-button"
                @click="handleButtonClick(index)">
                {{ phone }}
            </button>
        </div>
    </div>
</template>
  
<script>
import phonemesData from '../data/ipa-chart.json';
export default {
    props: {
        selectedHeads: Array,
        selectedPhones: Array,
        selectedDuration: Number,
        setSelectedPicIndex: Function,
        selectedPicIndex: Number
    },
    data() {
        return {
            visemes_bubble: phonemesData.visemes_bubble,
            currentImgSrc: phonemesData.visemes_bubble[0]?.img_src || null
        };
    },
    methods: {
        handleButtonClick(index) {
            this.setSelectedPicIndex(index, "word");
        }
    },
    mounted() {
        if (this.selectedHeads && this.selectedDuration) {
            this.selectedHeads.forEach((phone, index) => {
                setTimeout(() => {
                    const imgSrc = this.visemes_bubble[phone].img_src;
                    this.currentImgSrc = imgSrc;
                }, index * (this.selectedDuration / this.selectedHeads.length));
            });
        }
    },
    watch: {
        selectedPicIndex: function (newVal) {
            if (newVal !== null) {
                const selectedHeadsNum = this.selectedHeads[newVal];
                const imgSrc = this.visemes_bubble[selectedHeadsNum].img_src;
                this.currentImgSrc = imgSrc;
            }
        },
    }
}
</script>