<template>
    <div class="section3">
        <h2>Türkçe Alfabe</h2>
        <button v-for="(button, index) in buttons" :key="index" @click="handleButtonClick(
            button.id,
            button.duration,
            button.visemes_ex,
            button.visemes_in,
            button.phones
        )" class="alphabet-button">
            {{ button.turkishValue }}
        </button>
    </div>
</template>
  
<script>
export default {
    props: {
        handleButtonClick: {
            type: Function,
            required: true,
        },
        phonemes: {
            required: true,
        }
    },
    data() {
        return {
            ipaToTurkish: {
                'ɑ': 'A',
                'b': 'B',
                'dʒ': 'C',
                'tʃ': 'Ç',
                'd': 'D',
                'ɛ': 'E',
                'f': 'F',
                'ɡ': 'G',
                'ɰ': 'Ğ',
                'h': 'H',
                'ɪ': 'I',
                'i': 'İ',
                'ʒ': 'J',
                'k': 'K',
                'l': 'L',
                'm': 'M',
                'n': 'N',
                'oʊ': 'O',
                'ʊ': 'Ö',
                'p': 'P',
                'r': 'R',
                's': 'S',
                'ʃ': 'Ş',
                't': 'T',
                'u': 'U',
                'y': 'Ü',
                'v': 'V',
                'j': 'Y',
                'z': 'Z'
            },
            buttons: null
        };
    },
    methods: {
        listen() {
        },
    },
    mounted() {
        this.buttons = this.phonemes.map(phoneme => {
            let turkishValue = this.ipaToTurkish[phoneme.ipa] || null;
            return {
                ...phoneme,
                turkishValue: turkishValue
            };
        }).filter(button => button.turkishValue !== null);
        console.log(this.buttons);
    },
};
</script>
  