<template>
    <div class="section3">
        <h2>İpa Alfabesi</h2>
        <button v-for="(button, index) in buttons" :key="index" @click="handleButtonClick(
            button.id,
            button.duration,
            button.visemes_ex,
            button.visemes_in,
            button.phones
        )" class="alphabet-button">
            {{ button.ipa }}
        </button>
    </div>
</template>
  
<script>
export default {
    props: {
        handleButtonClick: {
            type: Function,
            required: true,
        },
        phonemes: {
            required: true,
        }
    },
    data() {
        return {
            buttons: null
        };
    },
    methods: {
        listen() {
        },
    },
    mounted() {
        this.buttons = this.phonemes;
    },
};
</script>
  