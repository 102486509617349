<template>
  <div id="app">
    <alphabet-tr v-if="isAlphabetTROpen" :phonemes="phonemes" :handleButtonClick="handleButtonClick" />

    <div class="toggle-button">
      <button class="circle-btn" @click="toggleAlphabetTROpen">
        <font-awesome-icon :icon="isAlphabetTROpen ? 'arrow-up' : 'arrow-down'" />
      </button>
    </div>

    <div class="near">
      <face-img :selectedFaces="selectedFaces" :selectedPhones="selectedPhones" :selectedDuration="selectedDuration"
        :setSelectedPicIndex="setSelectedPicIndex" :selectedPicIndex="selectedPicIndex" />

      <head-img :selectedHeads="selectedHeads" :selectedPhones="selectedPhones" :selectedDuration="selectedDuration"
        :setSelectedPicIndex="setSelectedPicIndex" :selectedPicIndex="selectedPicIndex" />
    </div>

    <alphabet-ipa v-if="isAlphabetOpen" :phonemes="phonemes" :handleButtonClick="handleButtonClick" />

    <div class="toggle-button">
      <button class="circle-btn" @click="toggleAlphabetOpen">
        <font-awesome-icon :icon="isAlphabetOpen ? 'arrow-up' : 'arrow-down'" />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import phonemesData from "./data/ipa-chart.json";
import { v4 as uuidv4 } from "uuid";
import mqtt from "mqtt";
import AlphabetTr from './components/alphabet-tr.vue';
import AlphabetIpa from './components/alphabet-ipa.vue';
import FaceImg from './components/face-img.vue';
import HeadImg from './components/head-img.vue';
import "./App.css";

export default {
  name: 'App',
  components: {
    AlphabetTr,
    AlphabetIpa,
    FaceImg,
    HeadImg,
  },
  data() {
    return {
      isAlphabetOpen: ref(false),
      isAlphabetTROpen: ref(false),
      selectedFaces: ref([]),
      selectedHeads: ref([]),
      selectedPhones: ref([]),
      selectedDuration: ref([]),
      selectedPicIndex: ref(null),
      phonemes: ref([]),
      audioContext: null,
      audioBuffer: null,
      appointmentId: null,
      role: null,
      mqttTopic: null,
      client: null,
      mqttComingJson: null,
    }
  },
  methods: {
    toggleAlphabetOpen() {
      this.isAlphabetOpen = !this.isAlphabetOpen;
    },
    toggleAlphabetTROpen() {
      this.isAlphabetTROpen = !this.isAlphabetTROpen;
    },
    handleButtonClick(id, duration, faces, heads, phones) {
      console.log(id, duration, faces, heads, phones);
      this.selectedFaces = faces;
      this.selectedHeads = heads;
      this.selectedPhones = phones;
      this.selectedDuration = duration;
      this.selectedHeads.forEach((phone, index) => {
        // her bir görseli belirli bir süre boyunca göstermek için setTimeout kullanıyoruz
        setTimeout(() => {
          // visemes_tom listesinden ilgili görselin URL'sini buluyoruz
          this.setSelectedPicIndex(index)
        }, index * (this.selectedDuration / this.selectedHeads.length));
        // her bir görseli belirli bir süre boyunca gösteriyoruz. Bu süre,
        // toplam duration süresinin selectedHeads dizisinin uzunluğuna bölünmesi ile elde edilir.
      });
      this.playSound(id, duration);
      // TODO: include logic here
      this.doPublish(
        `{ "id": "${id}","duration": "${duration}","faces": "${faces}","heads": "${heads}","phomes": "${phones}","role": "${this.role}","method":"alphabet"}`
      );
    },
    mqttButtonClick(id, duration, faces, heads, phones) {
      console.log(id, duration, faces, heads, phones);
      this.selectedFaces = faces.split(",");
      this.selectedHeads = heads.split(",");
      this.selectedPhones = phones.split(",");
      this.selectedDuration = duration;
      this.selectedHeads.forEach((phone, index) => {
        // her bir görseli belirli bir süre boyunca göstermek için setTimeout kullanıyoruz
        setTimeout(() => {
          // visemes_tom listesinden ilgili görselin URL'sini buluyoruz
          this.setSelectedPicIndex(index)
        }, index * (this.selectedDuration / this.selectedHeads.length));
        // her bir görseli belirli bir süre boyunca gösteriyoruz. Bu süre,
        // toplam duration süresinin selectedHeads dizisinin uzunluğuna bölünmesi ile elde edilir.
      });
      this.playSound(id, duration);
      // TODO: include logic here
    },
    setSelectedPicIndex(index, type) {
      this.selectedPicIndex = index;
      if (type === "word") {
        this.doPublish(
          `{ "index": "${index}","role": "${this.role}","method":"${type}"}`
        );
      }
      console.log(type);
    },
    playSound(id, duration) {
      id = Number(id);
      duration = Number(duration);

      if (Number.isFinite(id) && Number.isFinite(duration)) {
        const source = this.audioContext.createBufferSource();
        source.buffer = this.audioBuffer;
        source.connect(this.audioContext.destination);
        source.start(0, id);
        setTimeout(() => source.stop(), duration); // duration saniye sonra sesi durdur
      } else {
        console.log("Invalid id or duration value:", id, duration);
      }
    },
    createConnection(gameName, appointmentId) {
      const connectUrl = `wss://konusmaterapim.com:8084/mqtt`;
      const clientId = uuidv4();
      this.mqttTopic = gameName + "/" + appointmentId;
      try {
        const mqttClient = mqtt.connect(connectUrl, {
          clientId: clientId,
          username: "username", // burası gerçek kullanıcı adınızla değiştirilmeli
        });
        mqttClient.on("connect", () => {
          console.log("Connection succeeded!");
        });
        mqttClient.on("error", (error) => {
          console.log("Connection failed", error);
          mqttClient.end();
        });
        mqttClient.on("message", (topic, message) => {
          this.mqttMessageController(message);
        });
        this.client = mqttClient;
        this.doSubscribe(mqttClient, gameName + "/" + appointmentId);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
    },
    mqttMessageController(message) {
      // setMqttMessageCome(JSON.parse(message));
      console.log(JSON.parse(message));
      this.mqttComingJson = JSON.parse(message);
      if (this.mqttComingJson.role !== this.role && this.role != null) {
        if (this.mqttComingJson.method === "alphabet") {
          this.mqttButtonClick(this.mqttComingJson.id, this.mqttComingJson.duration, this.mqttComingJson.faces, this.mqttComingJson.heads, this.mqttComingJson.phomes);
        }
        else if (this.mqttComingJson.method === "word") {
          this.setSelectedPicIndex(this.mqttComingJson.index);
        }
      }
    },
    doSubscribe(client, mqttTopic) {
      console.log("doSubscribe");
      client.subscribe(mqttTopic, { qos: 0 }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log("Subscribe to topics res", res);
      });
    },
    doPublish(message) {
      this.client.publish(this.mqttTopic, message, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    }
  },
  mounted() {
    this.phonemes = phonemesData.phonemes;
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    fetch("https://alfabe.dilvekonusmaterapim.com/sounds/phonemes.mp3") // Ses dosyasını require ile alıyoruz
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => this.audioContext.decodeAudioData(arrayBuffer))
      .then((audioBuffer) => { this.audioBuffer = audioBuffer });
    // fetch("http://localhost:8080/sounds/phonemes.mp3") // Ses dosyasını require ile alıyoruz
    //   .then((response) => response.arrayBuffer())
    //   .then((arrayBuffer) => this.audioContext.decodeAudioData(arrayBuffer))
    //   .then((audioBuffer) => { this.audioBuffer = audioBuffer });
    const url = new URL(window.location.href);
    this.role = url.searchParams.get("role");
    this.appointmentId = url.searchParams.get("appointmentId");
    this.createConnection("alphabet", this.appointmentId);
    alert('Yükleme Tamamlandı Onayla.');
  },
}
</script>

<style>
/* include CSS styles here */
</style>

